<script>
import SearchResultCard from '@/components/discovery/SearchResultCard'
import DiscoveryList from '@/components/discovery/DiscoveryList'
import EmptyList from '@/components/general/EmptyList.vue'
import ErrorList from '@/components/general/ErrorList'
export default {
  name: 'DiscoveryFilter',
  components: {
    SearchResultCard,
    DiscoveryList,
    EmptyList,
    ErrorList
  },
  data () {
    return {
      filteredList: [],
      initialPositionsSearchParameters: {
        selectedFilter: '0',
        search: null,
        category: null,
        city: null,
        state: null,
        remoteWorkOnly: false,
        contractType: [],
        remuneration: '0',
        undefinedSalary: true,
        exclusivePCD: false,
        institute: null,
        segment: null,
        modality: [],
        workload: null,
        pricing: null
      },
      isLoading: true,
      errorState: false
    }
  },
  computed: {
    isIndividual () {
      return !!this.$route.name.includes('individual')
    }
  },
  watch: {
    '$route.query': {
      handler (search) {
        this.isLoading = true
        this.filteredList = []
        if (this.$route.query.type === 'contents' && this.isIndividual) {
          this.getContentDiscovery()
        } else if (this.$route.query.type === 'courses' && this.isIndividual) {
          this.getCourseDiscovery()
        } else {
          this.getPositionDiscovery()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    },
    showModalContentDetails (params) {
      this.$emit('showModalContentDetails', params)
    },
    getCourseDiscovery () {
      if (Object.keys(this.$route.query).length > 1) {
        const filter = { ...this.initialPositionsSearchParameters, ...this.$route.query, aggregator: this.getCompanyDomainAndWorkspace(), lang: this.$store.getters.getLanguage.split('-')[0], user: this.getUserInfo }
        this.$store.dispatch('attempGetSearchResultSample', { searchParameters: filter }).then(data => {
          this.filteredList = this.formatFilteredCourses(data)
          this.isLoading = false
        })
      } else {
        this.$store.dispatch('attemptGetCourseSuggestions', { aggregator: '' }).then(res => {
          this.filteredList = res
          this.isLoading = false
        })
      }
    },
    getPositionDiscovery () {
      const queryParams = this.$route.query
      if (queryParams.modality) {
        queryParams.modality = Array.isArray(queryParams.modality) ? queryParams.modality : [queryParams.modality]
      }
      const filter = { ...this.initialPositionsSearchParameters, ...this.$route.query, aggregator: this.getCompanyDomainAndWorkspace(), lang: this.$store.getters.getLanguage.split('-')[0], user: this.getUserInfo }
      this.$store.dispatch('attempGetSearchResultSample', { searchParameters: filter }).then(data => {
        if (filter.type === 'positions') {
          this.filteredList = data
          // this.formatFilteredPositions(data)
        } else {
          this.filteredList = this.formatFilteredCourses(data)
        }
        this.isLoading = false
      })
    },
    getContentDiscovery (preventScroll = false) {
      this.debounceEvent(() => {
        const payload = this.cloneObj({ filter: this.$route.query })
        const contentTypes = payload.filter.contentTypes
        payload.filter.contentTypes = Array.isArray(contentTypes) ? contentTypes : [contentTypes]
        if (payload.filter.skills) {
          payload.filter.skills = Array.isArray(payload.filter.skills) ? payload.filter.skills.map(s => JSON.parse(s).id) : [JSON.parse(payload.filter.skills).id]
        }
        payload.filter.textualSearch = payload.filter.search
        delete payload.filter.search
        this.$store.dispatch('attemptGetDiscoveryList', payload).then((data) => {
          if (data && data.error) this.errorState = true
          else {
            !preventScroll && this.smoothScrollTop()
            this.filteredList = data.data
          }
          this.isLoading = false
        })
      }, 20)
    },
    formatFilteredPositions (filteredPositions) {
      return filteredPositions.map(filteredPosition => ({
        type: 'position',
        id: filteredPosition.id,
        title: filteredPosition.title,
        name: filteredPosition.company.name,
        logo: filteredPosition.company.logo,
        image: filteredPosition.company.image,
        modality: filteredPosition.location,
        isFavorite: filteredPosition.isFavorite,
        workload: filteredPosition.type,
        remuneration: filteredPosition.salary,
        disabledOnly: filteredPosition.disabledOnly,
        isCandidate: filteredPosition.isCandidate,
        telecommute: filteredPosition.telecommute,
        eeocEnabled: true,
        ofccpVeteran: true,
        ofccpDisability: true
      }))
    },
    formatFilteredCourses (filteredCourses) {
      return filteredCourses.map(filteredCourse => ({
        type: 'course',
        id: filteredCourse.id,
        title: filteredCourse.name,
        provider: {
          name: filteredCourse.provider,
          logo: filteredCourse.logo
        },
        modality: filteredCourse.modality,
        price: filteredCourse.price,
        workload: filteredCourse.workload?.toString(),
        isFavorite: filteredCourse.isFavorite,
        courseUrl: filteredCourse.url
      }))
    }
  }
}
</script>
<template>
  <div class="discovery-search--result">
    <div class="discovery-search--content-result" v-if="$route.query.type === 'contents' && isIndividual">

      <div v-if="isLoading">
        <div class="skeleton-discovery--individual-filter">
          <v-skeleton-loader class="skeleton-card-individual" v-for="i in [1, 2, 3]" :key="i"
            type="card-avatar, article, actions" width="340px" height="380px" />
        </div>
      </div>
      <error-list :title="this.$t('empty.state.text.published:error.text')" v-else-if="errorState"></error-list>
      <div v-else>
        <discovery-list :list="filteredList" v-if="filteredList.length"
          @showModalAddListContent="params => showModalAddListContent(params)"
          @showModalContentDetails="params => showModalContentDetails(params)"
          ></discovery-list>
        <div class="margin-empty" v-else>
          <empty-list class="margin-empty" :title="$t('empty.state.title:empty.title')"
            :image="'/assets/images/empty-box.png'"></empty-list>
        </div>
      </div>
    </div>

    <div class="discovery-search--position-result"
      v-if="$route.query.type === 'positions' || $route.query.type === 'courses'">
      <div v-if="isLoading">
        <div class="skeleton-discovery--individual-filter-position">
          <v-skeleton-loader class="skeleton-card-individual-position" height="160px" type="card-avatar"
            v-for="i in ['a', 'b', 'c']" :key="i" />
        </div>
      </div>
      <div v-else>
        <div v-if="filteredList.length">
          <search-result-card v-for="(filtedItem, i) in filteredList" :key="i" :items="filtedItem"
            :type="$route.query.type" />
        </div>
        <div class="margin-empty" v-else>
          <empty-list class="margin-empty" :title="$t('empty.state.title:empty.title')"
            :image="'/assets/images/empty-box.png'"></empty-list>
        </div>
      </div>
    </div>

  </div>
</template>
<style lang="scss">
.discovery-search--result {
  margin-top: 20px;
}

.skeleton-discovery--individual-filter-position {

  .skeleton-card-individual-position {
    margin-bottom: 20px;
  }
}

::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__list-item-avatar-three-line {
    height: 100%;
  }
}

.skeleton-discovery--individual-filter {
  display: flex;
  justify-content: space-between;

}
</style>
